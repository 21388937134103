<template>
  <div v-if="config.appearance === 'input'" class="relative">
    <UiInput
      :model-value="props.params.ort"
      design="normal"
      name="ort"
      type="text"
      :autocomplete="false"
      @update:model-value="update('ort', $event)"
    >
      <template #label>
        {{ t('ort') }}
      </template>
      <template #icon>
        <UiIcon src="/svg/icons/location.svg" width="24" height="24" class="fill-neutral-500 " />
      </template>
    </UiInput>
    <div
      v-if="showModal && (searchRegions.length > 0 || showNoResultsMessage)"
      ref="modalWindow"
      class="absolute mt-1 inset-x-0 top-full z-40 bg-white text-left shadow-lg border border-neutral-100 rounded-md"
      @keydown.up.prevent="navigate(-1)"
      @keydown.down.prevent="navigate(1)"
    >
      <div
        v-for="(region, index) in searchRegions"
        :key="region"
        :class="{ 'bg-petrol-500 text-white': selectedIndex === index }"
        class="w-100 hover:bg-petrol-500 hover:text-white py-1 px-2 text-lg cursor-pointer rounded-md"
      >
        <p @click="chooseRegion(region.region, index)">
          {{ region.region }}
        </p>
      </div>
      <div
        v-if="showNoResultsMessage"
        class="w-100 py-1 px-2 text-lg rounded-md text-neutral-400"
      >
        {{ t('no_results') }}
      </div>
    </div>
  </div>
  <UiSelect
    v-if="config.appearance === 'select'"
    name="region"
    label="normal"
    class="font-semibold search-filter-place"
    :model-value="props.params.ort"
    @update:model-value="update('ort', $event)"
  >
    <template #label>
      {{ t('ort') }}
    </template>
    <template #icon>
      <UiIcon src="/svg/icons/location.svg" width="24" height="24" class="fill-neutral-500" />
    </template>
    <option value="">
      {{ config.allg_region }}
    </option>
    <option v-for="{ id, name, lvl } in config.places" :key="id" :value="id" :selected="name == props.params.ort || id == props.params.ort">
      {{ "\xA0".repeat(lvl*3+3) }}{{ name }}
    </option>
  </UiSelect>
</template>

<script setup>
const { translate: t } = useTranslations('SearchFilterLocation')
const { currentPortal } = usePortals()
const config = currentPortal.value.search_form

const emit = defineEmits(['update'])
const props = defineProps({
  params: {
    type: Object,
    default: () => ({})
  }
})

let localOrt = props.params?.ort ?? ''

const selectedIndex = ref(-1)
const searchRegions = ref([])
const showNoResultsMessage = ref(false)
const showModal = ref(false)
const regions = ref([])
const modalWindow = ref(null)

async function fetchRegions () {
  const { $backend } = useNuxtApp()
  regions.value = await $backend.regionalAutocomplete.get()
  regions.value = regions.value.data.data
}

if (config.appearance === 'input') {
  fetchRegions()
}

function navigate (direction) {
  const newIndex = selectedIndex.value + direction
  if (newIndex >= 0 && newIndex < searchRegions.value.length) {
    selectedIndex.value = newIndex
  }
}

function foundRegion (value) {
  let filteredRegions = regions.value.filter(item => item.region.toLowerCase().startsWith(value.toLowerCase()))
  filteredRegions.sort((a, b) => a.region.localeCompare(b.region))
  filteredRegions = filteredRegions.slice(0, 10)
  searchRegions.value = filteredRegions
  if (searchRegions.value.length > 0) {
    showNoResultsMessage.value = false
    return
  }
  showNoResultsMessage.value = true
}

function chooseRegion (region) {
  localOrt = region
  emit('update', { ort: region })
  showModal.value = false
}

const update = (key, val) => {
  localOrt = val
  if (val === '') {
    emit('update', { [key]: val })
    showModal.value = false
    return
  }

  if (config.appearance === 'select') {
    emit('update', { [key]: val })
    showModal.value = false
    return
  }

  if (config.appearance !== 'input') {
    return
  }
  foundRegion(val)
  showModal.value = true
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})

function handleClickOutside (event) {
  if (modalWindow.value && !modalWindow.value.contains(event.target)) {
    emit('update', { ort: localOrt })
    showModal.value = false
  }
}
</script>
<style lang="scss">
.search-filter-place {
  select.custom-select-fontsize{
    @apply text-lg;
    option{
      @apply text-lg;
    }
  }
}
</style>
