<template>
  <div
    tabindex="0"
    class="grid grid-flow-col auto-cols-max place-items-center gap-2 text-center"
    @keydown="handleKeydown"
  >
    <button
      type="button"
      tabindex="-1"
      aria-label="more"
      class="touch-manipulation select-none cursor-pointer"
      @click="update(props.modelValue - 1)"
    >
      <UiIcon
        src="/svg/icons/minus-icon.svg"
        width="34"
        height="34"
        alt="less"
        :class="[{'fill-neutral-500' : props.modelValue == 0}, {'fill-neutral-800' : props.modelValue >= 1}, 'inline-block']"
      />
    </button>
    <div
      role="spinbutton"
      :aria-valuenow="props.modelValue"
      :aria-valuetext="`${props.quantityType} ${props.modelValue}`"
      :aria-valuemin="props.min"
      :aria-valuemax="props.max"
      :aria-label="props.quantityType"
      class="w-5"
    >
      {{ props.modelValue }}
    </div>
    <button
      type="button"
      tabindex="-1"
      aria-label="less"
      class="touch-manipulation select-none cursor-pointer"
      @click="update(props.modelValue + 1)"
    >
      <UiIcon
        src="/svg/icons/plus-icon.svg"
        width="34"
        height="34"
        alt="more"
        class="fill-neutral-800 inline-block"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: Number,
    default: null
  },
  min: {
    type: Number,
    default: 0
  },
  max: {
    type: Number,
    default: 99
  },
  quantityType: {
    type: String,
    default: ''
  }
})

const emit = defineEmits<{(e: 'update:modelValue', value: number): void}>()

const update = (value) => {
  if (value >= props.min && value <= props.max) {
    emit('update:modelValue', parseInt(value))
  }
}

const handleKeydown = (event) => {
  if (event.key === 'ArrowRight') {
    update(props.modelValue + 1)
  } else if (event.key === 'ArrowLeft') {
    update(props.modelValue - 1)
  }
}
</script>
